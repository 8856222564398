import { useAppSelector } from 'hooks/redux.hooks';
import { getColumns } from '../getColumns';
import { useReducer } from 'react';
import { usePageContext, usePageData } from 'components/Page';
import { apiRoutes } from 'services';
import { GenericDocsList, GenericDocsListProps } from 'components/GenericDocsList';
import { TemplateByTypeData } from './types';

export const DocumentTemplates = () => {
    const { isApproved } = usePageContext();
    const selectedType = useAppSelector(state => state.ui.selectedType);
    let title = 'Document templates';

    if (selectedType) {
        title += ` - ${selectedType?.label}`;
    }

    const [, forceUpdate] = useReducer(() => getColumns(), []);
    const columns = getColumns(forceUpdate);

    const { data, loading } = usePageData<ContentListResponse<TemplateByTypeData>>({
        path: selectedType ? `${apiRoutes.templatesByType}/${selectedType.value}` : null,
        query: { isApproved },
    });

    const listProps: GenericDocsListProps<TemplateByTypeData> = {
        columns,
        data,
        loading,
        type: 'other',
        title,
        showTitle: true,
        saveCurrentPage: true,
    };

    return <GenericDocsList {...listProps} />;
};
