import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { EmptyTemplate } from './EmptyTemplate';
import { getPath, useTemplateData } from './useTemplate.hook';
import { TemplateLayout } from 'components/Layouts';
import { Steps } from './Steps/Steps';
import { PageHeader } from 'components/Page';
import { Menu, SaveTemplateButton, ViewToggle } from './Controls';
import { Divider, EditIcon, Loading } from 'components/atomic';
import { clearTemplateStateAction, setTemplateDataAction } from 'state/actions';
import { useAppDispatch } from 'hooks/redux.hooks';
import { TemplateView } from './TemplateView';
import { Button, Input } from 'antd';
import { editTemplate } from 'services';
import { getListenerAction } from 'hooks/data.hooks';

export const Template = ({ id }: Props) => {
    const dispatch = useAppDispatch();
    const { file, template, loading } = useTemplateData(id);
    const [editMode, setEditMode] = useState(false);
    const [newName, setNewName] = useState(template?.templateName || '');

    const toggleEditMode = () => {
        setEditMode(prev => !prev);
    };

    const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewName(event.target.value);
    };

    const handleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };

    const handleSave = async () => {
        await editTemplate(id, { name: newName });
        toggleEditMode();
        dispatch(getListenerAction(getPath(id)));
    };

    useEffect(() => {
        dispatch(setTemplateDataAction(template));
        if (template?.templateName) {
            setNewName(template.templateName);
        }
    }, [template]);

    useEffect(() => {
        return () => {
            dispatch(clearTemplateStateAction());
        };
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (!file || !template) {
        return <EmptyTemplate />;
    }

    return (
        <>
            <PageHeader
                title={
                    editMode ? (
                        <div className="template-title-edit">
                            <Input
                                onChange={handleTitleChange}
                                onKeyUp={handleEnter}
                                value={newName}
                                autoFocus
                            />
                            <Button type="primary" onClick={handleSave}>
                                Save
                            </Button>
                            <Button onClick={toggleEditMode}>Cancel</Button>
                        </div>
                    ) : (
                        <span>
                            Template: {template.templateName}{' '}
                            <div className="template-title-edit-icon" onClick={toggleEditMode}>
                                <EditIcon />
                            </div>
                        </span>
                    )
                }>
                <ViewToggle />
                <Menu id={id} isTemplateApproved={template?.isTemplateApproved} />
                <Divider vertical />
                <SaveTemplateButton />
            </PageHeader>
            <Divider />
            <TemplateLayout>
                <Steps />
                <TemplateView file={file} />
            </TemplateLayout>
        </>
    );
};

interface Props {
    id: number;
}
