import { useState } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';

import {
    convertDateRangeToString,
    DATE_FILTER_DEFAULT_VALUE,
    DateFilterOptions,
    DateRange,
    isPredefinedOption,
    saveCustomDates,
} from './helpers';
import { usePageContext } from '../context';
import { CustomDateRange } from './CustomDateRange';

export const DateFilter = () => {
    const { dateRange, setDateRange } = usePageContext();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string>(dateRange);
    const isPredefined = isPredefinedOption(value);
    const className = classNames('filter', 'date-filter', !isPredefined && 'custom');

    const handleChange = (selectedValue: string) => {
        setDateRange(selectedValue);
        setOpen(false);
    };

    const applyCustomDateRange = (range: DateRange) => {
        saveCustomDates(range);
        const selectedValue = convertDateRangeToString(range);
        handleChange(selectedValue);
        setValue(selectedValue);
    };

    const handleClick: React.MouseEventHandler<HTMLDivElement> = ({ target }) => {
        if (target instanceof HTMLElement && target.className == 'ant-select-selection-item') {
            setOpen(!open);
        }
    };

    const handleSelect = (selectedValue: string) => {
        setValue(selectedValue);
        setOpen(false);
    };

    return (
        <Select
            className={className}
            options={filterOptions}
            onChange={handleChange}
            open={open}
            value={value}
            onClick={handleClick}
            onSelect={handleSelect}
            dropdownRender={menu => (
                <>
                    {menu}
                    <CustomDateRange onApplyChanges={applyCustomDateRange} />
                </>
            )}
        />
    );
};

const filterOptions = [
    { value: DateFilterOptions.today },
    { value: DATE_FILTER_DEFAULT_VALUE },
    { value: DateFilterOptions.last30days },
    { value: DateFilterOptions.thisMonth },
    { value: DateFilterOptions.lastMonth },
];
