import moment from 'moment';

export enum DateFilterOptions {
    today = 'Today',
    last7days = 'Last 7 days',
    last30days = 'Last 30 days',
    thisMonth = 'This month',
    lastMonth = 'Last month',
}

export interface DateRange {
    from?: string;
    to?: string;
}

export const DATE_FILTER_DEFAULT_VALUE = DateFilterOptions.last7days;
export const DATE_RANGE_SEPARATOR = ' - ';
export const DISPLAY_DATE_FORMAT = 'MMM DD, YY';
export const CUSTOM_DATES_KEY = 'custom_dates';

export const convertFilterOptionToRange = (option: string): DateRange => {
    const today = moment().toISOString();
    const lastMonth = moment().subtract(1, 'months');

    switch (option) {
        case DateFilterOptions.today:
            return {
                from: moment().startOf('day').toISOString(),
                to: today,
            };
        case DateFilterOptions.last7days:
            return {
                from: moment().subtract(7, 'd').toISOString(),
                to: today,
            };
        case DateFilterOptions.last30days:
            return {
                from: moment().subtract(30, 'd').toISOString(),
                to: today,
            };
        case DateFilterOptions.thisMonth:
            return {
                from: moment().startOf('month').toISOString(),
                to: today,
            };
        case DateFilterOptions.lastMonth:
            return {
                from: lastMonth.startOf('month').toISOString(),
                to: lastMonth.endOf('month').toISOString(),
            };
        default:
            return getCustomDates();
    }
};

export const saveCustomDates = (range: DateRange) => {
    sessionStorage.setItem(CUSTOM_DATES_KEY, JSON.stringify(range));
};

export const getCustomDates = (): DateRange => {
    try {
        const range = sessionStorage.getItem(CUSTOM_DATES_KEY);

        if (range) {
            return JSON.parse(range);
        }
    } catch (error) {}

    return {};
};

export const convertDateRangeToString = (range: DateRange): string => {
    const from = moment(range.from).format(DISPLAY_DATE_FORMAT);
    const to = moment(range.to).format(DISPLAY_DATE_FORMAT);

    return `${from}${DATE_RANGE_SEPARATOR}${to}`;
};

export const isPredefinedOption = (option: string) => {
    return Object.values(DateFilterOptions).includes(option as DateFilterOptions);
};
