import { useEffect } from 'react';
import { addListener } from '@reduxjs/toolkit';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { closeAppNotification, showAppNotification } from 'state/actions';
import { NotificationData, NotificationType } from 'state/types';
import { SocketMessage, socket } from 'services/socket.service';
import { iconsMap } from './icons.map';
import { typesMap } from './types.map';
import { ImportNotification } from './ImportNotification';

export const AppNotifications = () => {
    const dispatch = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();
    const currentWorkspaceId = useAppSelector(state => state.user.data?.currentWorkspaceId);

    const show = (data: NotificationData) => {
        api.open({
            message: data.title,
            description: data.content,
            placement: 'bottomLeft',
            className: 'app-notification',
            icon: iconsMap[data.type] || null,
            duration: 10,
        });
    };

    const hideAll = () => {
        api.destroy();
    };

    const handleSocketMessage = (message: SocketMessage) => {
        // Show notification only for current workspace id
        if (message.workspaceId !== currentWorkspaceId) {
            return;
        }

        let type: NotificationType = typesMap[message.status];

        type = type || 'error';

        let isTypeMatchError = message.details?.includes('Type matching');

        show({
            type,
            content: (
                <ImportNotification
                    date={message.date}
                    id={message.entityId}
                    type={type}
                    isTypeMatchError={isTypeMatchError}
                    onNavigate={hideAll}
                />
            ),
            title: resolveTitle(message, isTypeMatchError),
        });
    };

    const resolveTitle = (message: SocketMessage, isTypeMatchError: boolean): string => {
        let title: string = message.message;
        if (message.details) {
            title += isTypeMatchError ? ': match template' : ': ' + message.details;
        }

        return title;
    };

    useEffect(() => {
        const socketSubscribe = socket.subscribe(handleSocketMessage);
        const notificationSubscribe = dispatch(
            addListener({
                actionCreator: showAppNotification,
                effect: ({ payload }) => show(payload),
            })
        ) as unknown as () => void;

        const notificationCloseSubscribe = dispatch(
            addListener({
                actionCreator: closeAppNotification,
                effect: () => hideAll(),
            })
        ) as unknown as () => void;

        return () => {
            socketSubscribe();
            notificationSubscribe();
            notificationCloseSubscribe();
        };
    }, [currentWorkspaceId]);

    return contextHolder;
};
