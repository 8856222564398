import { Divider } from 'components/atomic';
import { TemplatesLayout } from 'components/Layouts';
import { AmountFilter, Page, PageHeader } from 'components/Page';
import { ApprovedFilter } from 'components/Page/ApprovedFilter/ApprovedFilter';
import {
    AddDocumentTemplateButton,
    AddDocumentTypeButton,
    DocumentTemplates,
    TemplatesList,
    TemplateTypes,
} from 'components/Templates';
import { useAppSelector } from 'hooks/redux.hooks';

export const TemplatesPage = () => {
    const searchQuery = useAppSelector(state => state.search.searchQuery);

    return (
        <Page pageId="templates">
            <PageHeader title="Doc Templates">
                <ApprovedFilter />
                <AmountFilter />
                <Divider vertical />
                <AddDocumentTypeButton type="button" />
                <AddDocumentTemplateButton />
            </PageHeader>
            {!searchQuery ? (
                <TemplatesLayout left={<TemplateTypes />} right={<DocumentTemplates />} />
            ) : (
                <TemplatesList />
            )}
        </Page>
    );
};
