import { useNavigate } from 'react-router-dom';

import { ListActionsProps, ListActions } from 'components/List';
import { routes } from 'navigator';
import { useState } from 'react';
import { DeleteDocumentTemplateModal } from './DeleteDocumentTemplateModal';
import { getTemplateData, updateTemplateApproveStatus } from 'services';
import { useDrawer } from 'components/DrawerContainer';
import { DocumentTemplateForm } from './DocumentTemplateForm';
import { TemplateByTypeData } from './types';
import { TemplateCloneForm } from './TemplateCloneForm';
import { MenuIcon } from '../../atomic';
import { Dropdown, MenuProps } from 'antd';

export const DocumentTemplatesEdit = ({ item, typeId, forceUpdate }: Props) => {
    const navigate = useNavigate();
    const { openDrawer } = useDrawer();
    const [open, setOpen] = useState(false);

    const handleDeleteClick = () => {
        setOpen(true);
    };

    const handleViewClick = () => {
        navigate(`${routes.templates}/${item.id}`);
    };

    const handleCloneClick = () => {
        if (item) {
            openDrawer(
                `Clone template ${item.templateName}`,
                <TemplateCloneForm
                    data={{
                        name: item.templateName + '_copy',
                        type: typeId,
                    }}
                    id={item.id}
                />
            );
        }
    };

    const handleApproveClick = async () => {
        item.isTemplateApproved = !item.isTemplateApproved;
        await updateTemplateApproveStatus(item.id, { isTemplateApproved: item.isTemplateApproved });
        if (forceUpdate) {
            forceUpdate();
        }
    };

    const handleEditClick = async () => {
        const template = await getTemplateData(item.id);

        if (template) {
            openDrawer(
                `Edit template ${template.templateName}`,
                <DocumentTemplateForm
                    data={{
                        name: template.templateName,
                        businessEntityId: template.businessEntityId,
                        type: typeId,
                        userIds: template.userIds,
                    }}
                    id={item.id}
                    customDataList={template.customDataList}
                />
            );
        }
    };

    const items: MenuProps['items'] = [
        {
            key: 'edit',
            label: <div onClick={handleEditClick}>Settings</div>,
        },
        {
            key: 'clone',
            label: <div onClick={handleCloneClick}>Clone template</div>,
        },
    ];

    const listActionProp: ListActionsProps = {
        onDeleteClick: handleDeleteClick,
    };

    if (item.isTemplateApproved) {
        items.push({
            key: 'unApprove',
            label: <div onClick={handleApproveClick}>UnApprove</div>,
        });
    } else {
        listActionProp.onApproveClick = handleApproveClick;
    }

    if (item.currentStep === 'COMPLETE') {
        listActionProp.onViewClick = handleViewClick;
    } else {
        listActionProp.onSetClick = handleViewClick;
    }

    return (
        <div className="template-fields-form-checkboxes">
            <ListActions {...listActionProp} />
            <Dropdown
                menu={{ items }}
                trigger={['click']}
                placement="bottom"
                arrow={{ pointAtCenter: true }}>
                <div className="template-type">
                    <MenuIcon />
                </div>
            </Dropdown>
            <DeleteDocumentTemplateModal
                open={open}
                setOpen={setOpen}
                id={item.id}
                typeId={typeId}
                templateName={item.templateName}
            />
        </div>
    );
};

interface Props {
    item: TemplateByTypeData;
    typeId: number;
    forceUpdate?: () => void;
}
