import { UserRole } from 'components/Users/types';

export enum UserActionTypes {
    SET_AUTH_STATE = 'SET_AUTH_STATE',
    SET_USER_DATA = 'SET_USER_DATA',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    UPLOAD_IMAGE = 'UPLOAD_IMAGE',
    SET_IMAGE = 'SET_IMAGE',
    SET_WORKSPACE = 'SET_WORKSPACE',
    SET_LOADING = 'SET_LOADING',
    WORKSPACE_CHANGED = 'WORKSPACE_CHANGED',
}

export interface UserState {
    isAuth: boolean;
    data: UserData | null;
    image: string;
    loading: boolean;
}

export interface UserData {
    currentWorkspaceId: number;
    email: string;
    name: string;
    role: UserRole;
    workspaces: Workspace[];
}

export interface Workspace extends WithId {
    name: string;
    showAddressTable: boolean;
}
