import { routes } from 'navigator';

import { SideMenuItem } from './SideMenuItem';
import {
    OverviewIcon,
    ErrorQueueIcon,
    ImportedDocsIcon,
    SettingsIcon,
    TemplateIcon,
    UserIcon,
    Address,
} from 'components/atomic';
import { AdminGuard } from 'components/Guards/AdminGuard';
import { useAppSelector } from 'hooks/redux.hooks';
import { EditorGuard } from '../Guards/EditorGuard';

export const SideMenu = () => {
    const showAddressTable = useAppSelector(state => state.ui.showAddressTable);

    return (
        <div className="side-menu">
            <SideMenuItem path={routes.overview} text="Overview" icon={<OverviewIcon />} />
            <SideMenuItem path={routes.errorQueue} text="Error Queue" icon={<ErrorQueueIcon />} />
            <SideMenuItem
                path={routes.documents}
                text="Imported Docs"
                icon={<ImportedDocsIcon />}
            />
            <EditorGuard>
                <SideMenuItem
                    path={routes.templates}
                    text="Doc Templates"
                    icon={<TemplateIcon />}
                />
            </EditorGuard>
            <AdminGuard>
                <SideMenuItem path={routes.users} text="Users" icon={<UserIcon />} />
            </AdminGuard>
            {showAddressTable && (
                <SideMenuItem path={routes.addressTable} text="Addresses" icon={<Address />} />
            )}
            <AdminGuard>
                <SideMenuItem
                    path={routes.systemSettings}
                    text="System Settings"
                    icon={<SettingsIcon />}
                />
            </AdminGuard>
        </div>
    );
};
