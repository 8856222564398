import { usePageData } from 'components/Page';
import { apiRoutes } from 'services';
import { DocumentApproveStatus, DocumentData, DocumentsDataResponse } from './types';
import { DocumentsListControls } from './DocumentsListControls';
import { getColumns } from './getColumns';
import {
    DocumentsListType,
    GenericDocsList,
    GenericDocsListProps,
} from 'components/GenericDocsList';

export const DocumentsList = ({ type = 'documents', isApproved }: Props) => {
    const { data, loading } = usePageData<DocumentsDataResponse>({
        path: apiRoutes.documents,
        query: { isApproved },
    });

    const listProps: GenericDocsListProps<DocumentData> = {
        columns: getColumns(type),
        controls: <DocumentsListControls />,
        data,
        loading,
        type,
        title: `All Imports ${data ? `(${data.total})` : ''}`,
    };

    return <GenericDocsList {...listProps} />;
};

interface Props {
    type?: DocumentsListType;
    isApproved: DocumentApproveStatus;
}
