import { createListenerMiddleware } from '@reduxjs/toolkit';

import {
    expandStepAction,
    columnsToTemplateAction,
    saveColumnsToTemplateAction,
    setColumnsToTemplateValueAction,
    toggleViewAction,
    tipAction,
    selectionInfoAction,
    getTemplateDataAction,
} from 'state/actions';
import { RootState } from 'state/store.types';
import { saveTemplateStep } from 'services';
import {
    ColumnsToTemplateItem,
    ColumnsToTemplateState,
    CommonStepItem,
    StepCommonMethods,
} from 'state/types';
import { stepBase } from './stepBase';

export const columnsToTemplateMiddleware = createListenerMiddleware<RootState>();

columnsToTemplateMiddleware.startListening({
    actionCreator: saveColumnsToTemplateAction,
    effect: async ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const { id, columnsToTemplate } = template;

        dispatch(columnsToTemplateAction({ loading: true }));

        await saveTemplateStep(id, 'STEP_4', {
            dynamicRulesMap: columnsToTemplate.items.reduce(
                (acc, item) => ({ ...acc, [item.id]: item.value?.selected }),
                {}
            ),
            dynamicRulesFreeTextMap: columnsToTemplate.items.reduce(
                (acc, item) => ({ ...acc, [item.id]: item.value?.freeText }),
                {}
            ),
        });

        dispatch(columnsToTemplateAction({ loading: false }));

        if (!payload?.saveAll) {
            dispatch(getTemplateDataAction());
            dispatch(expandStepAction(-1));
            dispatch(toggleViewAction(false));
        }
    },
});

columnsToTemplateMiddleware.startListening({
    actionCreator: columnsToTemplateAction,
    effect: ({ payload }, { dispatch }) => {
        if (payload.items) {
            const valid = columnsToTemplate.validate(payload.items);
            const active = columnsToTemplate.nextItemIndex(payload.items);
            const selectedValues = getSelectedValues(payload.items);
            dispatch(columnsToTemplate.action({ valid, active, selectedValues }));
        }
    },
});

columnsToTemplateMiddleware.startListening({
    actionCreator: setColumnsToTemplateValueAction,
    effect: ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const items = columnsToTemplate.itemsCopy(template.columnsToTemplate.items);
        const item = items.find(({ id }) => id === payload.id);

        if (item) {
            item.value = payload.value;
        }

        dispatch(columnsToTemplate.action({ items }));
    },
});

export const columnsToTemplate: StepCommonMethods<ColumnsToTemplateState> = {
    ...stepBase,

    stepName: 'columnsToTemplate',
    action: columnsToTemplateAction,

    initState(dispatch, template) {
        const items: CommonStepItem<ColumnsToTemplateItem>[] = template.fields
            .filter(field => field.isTable && template.table.headerRule?.fieldId !== field.fieldId)
            .map(field => {
                let value: ColumnsToTemplateItem = {};

                if (template.table.dynamicRules) {
                    Object.entries(template.table.dynamicRules).forEach(([result, fieldId]) => {
                        if (fieldId === field.fieldId) {
                            value.selected = result;
                        }
                    });
                }

                if (template.table.dynamicRulesFreeText) {
                    Object.entries(template.table.dynamicRulesFreeText).forEach(
                        ([result, fieldId]) => {
                            if (fieldId === field.fieldId) {
                                value.freeText = result;
                            }
                        }
                    );
                }

                return {
                    title: field.label,
                    value,
                    id: field.fieldId,
                };
            });

        dispatch(this.action({ items }));
    },
    stepChange(dispatch, state) {
        dispatch(selectionInfoAction(null));
        dispatch(tipAction(null));
        this.handleStepChange(dispatch, state.columnsToTemplate.items);
    },
    selectionChange() {},
    clearValue(dispatch, state) {
        const { active } = state.columnsToTemplate;
        const items = this.itemsCopy(state.columnsToTemplate.items);

        if (typeof active === 'number') {
            items[active].value = null;
        }

        dispatch(this.action({ items }));
    },
};

const getSelectedValues = (items: CommonStepItem<ColumnsToTemplateItem>[]) => {
    return items.filter(({ value }) => !!value).map(({ value }) => value as ColumnsToTemplateItem);
};
