import { Key, useEffect, useState } from 'react';
import { TableProps } from 'antd/es/table';
import { useSearchParams } from 'react-router-dom';

import { List } from 'components/List/List';
import { usePageContext } from 'components/Page';
import { DocumentsListType } from './types';
import { GenericDocsListContextProvider } from './context';

export const GenericDocsList = <T extends WithId>({
    type = 'documents',
    showTitle = false,
    data,
    title,
    loading,
    columns,
    controls,
    saveCurrentPage = false,
}: GenericDocsListProps<T>) => {
    const [selected, setSelected] = useState<T[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const { itemsAmount, dataPage, setDataPage } = usePageContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = searchParams.get('page') || 1;

    const rowSelection: TableProps<T>['rowSelection'] =
        type === 'documents'
            ? {
                  selectedRowKeys,
                  onChange: (selectedKeys, selectedRows) => {
                      setSelected([...selectedRows]);
                      setSelectedRowKeys(selectedKeys);
                  },
              }
            : undefined;

    const clearSelection = () => {
        setSelectedRowKeys([]);
        setSelected([]);
    };

    useEffect(() => {
        if (!saveCurrentPage) {
            return;
        }

        const page = searchParams.get('page');

        if (page) {
            setDataPage(Number(page));
        }
    }, [searchParams]);

    const handlePageChange: TableProps<T>['onChange'] = pagination => {
        if (saveCurrentPage) {
            setSearchParams({ page: pagination.current?.toString() ?? '' });
        }

        setDataPage(pagination.current);
    };

    return (
        <GenericDocsListContextProvider value={{ selected, clearSelection }}>
            <List
                title={title}
                controls={controls}
                columns={columns}
                dataSource={data?.content}
                rowKey={item => item.id.toString()}
                loading={loading}
                rowSelection={rowSelection}
                total={data?.total}
                pageSize={itemsAmount}
                currentPage={saveCurrentPage ? Number(currentPage) : dataPage}
                onChange={handlePageChange}
                showTitle={type === 'documents' || showTitle}
            />
        </GenericDocsListContextProvider>
    );
};

export interface GenericDocsListProps<T extends WithId> {
    type?: DocumentsListType;
    title: string;
    data: ContentListResponse<T> | null;
    loading: boolean;
    controls?: React.ReactNode;
    columns: TableProps<T>['columns'];
    showTitle?: boolean;
    saveCurrentPage?: boolean;
}
