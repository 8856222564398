import { AmountFilter, DateFilter, Page, PageHeader } from 'components/Page';
import { Divider } from 'components/atomic';
import { ImportButton } from 'components/ImportButton';
import { ApprovedFilter } from 'components/Page/ApprovedFilter/ApprovedFilter';
import { DocumentsPageList } from './DocumentsPageList';

export const DocumentsPage = () => {
    return (
        <Page pageId="documents">
            <PageHeader title="Imported Docs">
                <ApprovedFilter />
                <AmountFilter />
                <DateFilter />
                <Divider vertical />
                <ImportButton text="Manual Import" />
            </PageHeader>
            <DocumentsPageList />
        </Page>
    );
};
