import { useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

import { PageContext, usePageContext } from 'components/Page';
import { useData } from 'hooks/data.hooks';
import { ApiParams } from 'services';
import { convertFilterOptionToRange } from './DateFilter';
import { useAppSelector } from 'hooks/redux.hooks';

export const usePageData = <T>({ path, resetSearch = false, query }: PageDataParams) => {
    const context = usePageContext();
    const searchQuery = useAppSelector(state => state.search.searchQuery);
    const [reset, setReset] = useState(resetSearch);
    const [searchParams] = useSearchParams();
    const { itemsAmount, dataPage, dateRange } = context;
    const pageNum = searchParams.get('page');
    const params = useMemo(
        () =>
            getQuery(
                {
                    itemsAmount,
                    dataPage: pageNum ? parseInt(pageNum) : dataPage,
                    dateRange,
                },
                searchQuery,
                reset
            ),
        [itemsAmount, dataPage, dateRange, searchQuery, reset, pageNum]
    );

    useEffect(() => {
        setReset(false);
    }, []);

    const req = path
        ? queryString.stringifyUrl(
              { url: path, query: { ...params, ...query } },
              { skipNull: true }
          )
        : null;

    return useData<T>(req);
};

export const getQuery = (
    {
        itemsAmount,
        dataPage,
        dateRange,
    }: Pick<PageContext, 'itemsAmount' | 'dataPage' | 'dateRange'>,
    searchQuery: string,
    reset: boolean
) => {
    const range = convertFilterOptionToRange(dateRange);

    const query: ApiParams = {
        page: dataPage - 1,
        size: itemsAmount,
        ...range,
    };

    if (!reset && searchQuery) {
        query.searchQuery = searchQuery;
    }

    return query;
};

interface PageDataParams {
    path: string | null;
    resetSearch?: boolean;
    query?: Record<string, string | number | boolean | undefined | null>;
}
