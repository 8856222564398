import { Button } from 'antd';
import { useState } from 'react';

import { SyncIcon } from 'components/atomic';
import { syncTranslationsTable } from '../../../services';
import { useAppDispatch } from 'hooks/redux.hooks';
import { showAppNotification } from 'state/actions';

export const SyncButton = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const handleSyncTranslationTable = async () => {
        setLoading(true);

        try {
            await syncTranslationsTable();
            dispatch(
                showAppNotification({
                    type: 'success',
                    title: 'Success',
                    content: 'Translation table synced successfully',
                })
            );
        } catch (error) {
            dispatch(
                showAppNotification({
                    type: 'error',
                    title: 'Error',
                    content: 'Failed to sync translation table',
                })
            );
        }

        setLoading(false);
    };

    return (
        <Button
            className="translations-sync"
            size="middle"
            onClick={handleSyncTranslationTable}
            disabled={loading}
            loading={loading}
            icon={
                <div className="icon">
                    <SyncIcon />
                </div>
            }>
            Sync table
        </Button>
    );
};
