import { Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { setWorkspaceAction } from 'state/actions';

export const WorkspaceSelect = () => {
    const { data, loading } = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();

    const handleChange = (value: number) => {
        dispatch(setWorkspaceAction(value));
    };

    return (
        <div className="workspace-select-container">
            <Select
                className="workspace-select"
                options={[
                    {
                        label: <span>Workspace</span>,
                        title: 'Workspace',
                        options:
                            data?.workspaces.map(item => ({
                                label: item.name,
                                value: item.id,
                            })) || [],
                    },
                ]}
                value={data?.currentWorkspaceId}
                onChange={handleChange}
                loading={loading}
                disabled={loading}
            />
        </div>
    );
};
