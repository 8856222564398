import { createAction } from '@reduxjs/toolkit';
import { LoginResponse } from 'services';

import { UserActionTypes, UserData } from 'state/types';

export const setAuthStateAction = createAction<boolean>(UserActionTypes.SET_AUTH_STATE);
export const setUserDataAction = createAction<UserData | null>(UserActionTypes.SET_USER_DATA);
export const loginAction = createAction<LoginResponse>(UserActionTypes.LOGIN);
export const logoutAction = createAction(UserActionTypes.LOGOUT);
export const uploadUserImageAction = createAction<File>(UserActionTypes.UPLOAD_IMAGE);
export const setUserImageAction = createAction<string>(UserActionTypes.SET_IMAGE);
export const setWorkspaceAction = createAction<number>(UserActionTypes.SET_WORKSPACE);
export const setUserLoadingAction = createAction<boolean>(UserActionTypes.SET_LOADING);
export const workspaceChangedAction = createAction(UserActionTypes.WORKSPACE_CHANGED);
