import { Select } from 'antd';

import { usePageContext } from '../context';
import { DocumentApproveStatus } from 'components/Documents/types';

export const ApprovedFilter = () => {
    const { isApproved = APPROVED_FILTER_DEFAULT_VALUE, setIsApproved } = usePageContext();

    const handleOnChange = (value: DocumentApproveStatus) => {
        setIsApproved(value);
    };

    return (
        <Select
            className="filter"
            options={filterOptions}
            onChange={handleOnChange}
            defaultValue={isApproved}
        />
    );
};

export const APPROVED_FILTER_DEFAULT_VALUE = null;

const filterOptions = [
    { value: APPROVED_FILTER_DEFAULT_VALUE, label: 'Show All States' },
    { value: true, label: 'Approved' },
    { value: false, label: 'Not approved' },
];
