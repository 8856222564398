import { Tabs, TabsProps } from 'antd';

import { DocumentsList } from 'components/Documents/DocumentsList';
import { ErrorQueueList } from 'components/ErrorQueue';
import { Container } from 'components/atomic';

export const Imports = () => {
    return (
        <Container className="overview-tabs-container">
            <Tabs defaultActiveKey="1" items={items} />
        </Container>
    );
};

const items: TabsProps['items'] = [
    {
        key: '1',
        label: 'Recent imports',
        children: <DocumentsList type="overview" isApproved={true} />,
    },
    {
        key: '2',
        label: 'Failed imports',
        children: <ErrorQueueList type="overview" />,
    },
    {
        key: '3',
        label: 'To approve',
        children: <DocumentsList type="overview" isApproved={false} />,
    },
];
