import { Dropdown, MenuProps } from 'antd';

import { Logo } from 'components/Logo/Logo';
import { Logout } from 'components/Logout/Logout';
import { MenuIcon } from 'components/atomic';
import { UserInfo } from 'components/Users/UserInfo';
import { Search } from 'components/Search/Search';
import { About } from '../About/About';
import { useAppSelector } from 'hooks/redux.hooks';
import { WorkspaceSelect } from 'components/WorkspaceSelect/WorkspaceSelect';

export const Header = () => {
    const isAuth = useAppSelector(state => state.user.isAuth);

    return (
        <div className="header">
            <div className="header-left">
                <Logo type="header" size="small" />
                <WorkspaceSelect />
            </div>
            {isAuth && (
                <div className="header-controls">
                    <Search />
                    <div className="header-item">
                        <UserInfo />
                    </div>
                    <div className="header-item">
                        <Dropdown menu={{ items }}>
                            <div className="header-menu">
                                <MenuIcon />
                            </div>
                        </Dropdown>
                    </div>
                </div>
            )}
        </div>
    );
};

const items: MenuProps['items'] = [
    {
        key: 'logout',
        label: <Logout />,
    },
    {
        type: 'divider',
    },
    {
        key: 'about',
        label: <About />,
        disabled: true,
    },
];
