export enum routes {
    login = '/login',
    overview = '/overview',
    singup = '/signup',
    errorQueue = '/error-queue',
    documents = '/documents',
    templates = '/templates',
    users = '/users',
    systemSettings = '/system-settings',
    addressTable = '/address-table',
    error = '/error',
}

export enum routeParams {
    templateId = 'templateId',
    documentId = 'documentId',
}

// Routes where users should not be redirected on workspace change
export const workspaceRoutes: string[] = [
    routes.overview,
    routes.errorQueue,
    routes.documents,
    routes.templates,
    routes.users,
    routes.systemSettings,
    routes.addressTable,
    routes.error,
];
