import { Select } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';

import { useTemplate } from 'components/Template/useTemplate.hook';
import { ToggleButton } from 'components/atomic';
import { tableAction } from 'state/actions';
import { MarkTableHeader } from './MarkTableHeader';

export const MarkTableManualSettings = () => {
    const { dispatch, table, template } = useTemplate();
    const {
        useRowLinesManual,
        useColumnRowSeparator,
        columnRowSeparator,
        addSeparator,
        separateByColumnBoundaries,
    } = table;

    const selectColumnOptions: SelectColumnOptions[] | undefined = template?.fields
        .filter(field => field.isTable)
        .map(({ label, fieldId }) => ({
            label,
            value: fieldId,
        }));

    const handleUseRowLinesToggle: SwitchChangeEventHandler = event => {
        dispatch(tableAction({ useRowLinesManual: event.valueOf() }));
    };

    const handleSelectColumnToggle: SwitchChangeEventHandler = event => {
        dispatch(tableAction({ useColumnRowSeparator: event.valueOf() }));
    };

    const handleseparateByColumnBoundariesToggle: SwitchChangeEventHandler = event => {
        dispatch(tableAction({ separateByColumnBoundaries: event.valueOf() }));
    };

    const handleAddTableHeader: SwitchChangeEventHandler = event => {
        dispatch(
            tableAction({
                addSeparator: event.valueOf(),
                separatorSelection: event.valueOf() ? 'title' : null,
            })
        );
    };

    const handleSelectColumn = (value: number) => {
        dispatch(tableAction({ columnRowSeparator: value }));
    };

    return (
        <>
            <ToggleButton
                label="Rows are separated by lines"
                checked={useRowLinesManual}
                onChange={handleUseRowLinesToggle}
            />
            <ToggleButton
                label="Select column which separates rows"
                checked={useColumnRowSeparator}
                onChange={handleSelectColumnToggle}
            />
            {useColumnRowSeparator && (
                <Select
                    placeholder="Select column"
                    options={selectColumnOptions}
                    disabled={!selectColumnOptions}
                    onChange={handleSelectColumn}
                    value={columnRowSeparator}
                />
            )}
            <ToggleButton
                label="Separate text by columns boundaries"
                checked={separateByColumnBoundaries}
                onChange={handleseparateByColumnBoundariesToggle}
            />
            <ToggleButton
                label="Set multiple tables separator"
                checked={addSeparator}
                onChange={handleAddTableHeader}
            />
            {addSeparator && <MarkTableHeader />}
        </>
    );
};

interface SelectColumnOptions {
    value: number;
    label: string;
}
