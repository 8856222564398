import { LicenseResponseData, OverviewData, UserData } from 'state/types';

export const replaceEmailText = (
    text: string,
    user: UserData | null,
    overview: OverviewData,
    license: LicenseResponseData | null
) => {
    let replaced = text;

    Object.entries(contentMap).forEach(([content, key]) => {
        if (user && key in user) {
            replaced = replaced.replaceAll(content, String(user[key as keyof UserData]));
        }

        if (key in overview) {
            replaced = replaced.replaceAll(content, overview[key as keyof OverviewData].toString());
        }

        if (license && key in license) {
            replaced = replaced.replaceAll(
                content,
                license[key as keyof LicenseResponseData].toString()
            );
        }
    });

    return replaced;
};

const contentMap: Record<string, keyof UserData | keyof OverviewData | keyof LicenseResponseData> =
    {
        '[Admins Name]': 'name',
        '[Number of Documents]': 'importedDocuments',
        '[Number of Pages]': 'importedPages',
        '[Total Time Saved]': 'timeSaved',
        '[Total Money Saved]': 'moneySaved',
        '[Company Name]': 'company',
        '[Client Name]': 'company',
    };
