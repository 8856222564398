import { useReducer } from 'react';

import { usePageContext, usePageData } from 'components/Page';
import { apiRoutes } from 'services';
import { TemplatesAllDataResponse } from './types';
import { GenericDocsList, GenericDocsListProps } from 'components/GenericDocsList';
import { TemplateByTypeData } from '../DocumentTemplates/types';
import { getColumns } from '../getColumns';

export const TemplatesList = () => {
    const { isApproved } = usePageContext();
    const { data, loading } = usePageData<TemplatesAllDataResponse>({
        path: apiRoutes.templatesAll,
        query: { isApproved },
    });
    const [, forceUpdate] = useReducer(() => getColumns(), []);

    const listProps: GenericDocsListProps<TemplateByTypeData> = {
        columns: getColumns(forceUpdate),
        data,
        loading,
        title: `Templates ${data ? `(${data.total})` : ''}`,
    };

    return <GenericDocsList {...listProps} />;
};
