import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import { AvatarIcon, StatusBadge, StatusBadgeType } from 'components/atomic';
import { UserRole, UserStatus, UsersListItem, UsersDataResponse } from './types';
import { getUserRoleIcon, getDisplayUserRole, getDisplayUserStatus } from './helpers';
import { List, getListPaginationConfig, ListIconCelText } from 'components/List';
import { usePageContext, usePageData } from 'components/Page';
import { apiRoutes } from 'services';
import { UsersListItemEdit } from './UsersListItemEdit';
import { UsersPlan } from './UsersPlan';

export const UsersList = () => {
    const { itemsAmount } = usePageContext();
    const { data, loading } = usePageData<UsersDataResponse>({ path: apiRoutes.users });

    const showPagination = data && data.content.length > itemsAmount;
    const pagination = showPagination ? getListPaginationConfig(itemsAmount) : false;

    return (
        <List
            title="All Users"
            columns={columns}
            dataSource={data?.content}
            rowKey={item => item.uid}
            pagination={pagination}
            loading={loading}
            controls={<UsersPlan usersCount={data?.total} />}
        />
    );
};

export const columns: ColumnsType<UsersListItem> = [
    {
        key: 'name',
        dataIndex: 'name',
        render: (name: string, item) => (
            <ListIconCelText
                text={name}
                subtext={`Created ${moment(item.createdAt).format('MMM DD, YYYY')}`}
                icon={<AvatarIcon />}
            />
        ),
    },
    {
        key: 'email',
        dataIndex: 'email',
    },
    {
        key: 'role',
        dataIndex: 'role',
        render: (role: UserRole) => (
            <div className="user-list-role">
                <div className="user-list-role-icon">{getUserRoleIcon(role)}</div>
                <span>{getDisplayUserRole(role)}</span>
            </div>
        ),
    },
    {
        key: 'status',
        dataIndex: 'status',
        render: (value: UserStatus) => {
            return (
                <StatusBadge type={value.toLowerCase() as StatusBadgeType}>
                    {getDisplayUserStatus(value)}
                </StatusBadge>
            );
        },
    },
    {
        key: 'edit',
        render: (value, data) => {
            return <UsersListItemEdit data={data} />;
        },
    },
];
