import React from 'react';
import axios, { AxiosError, AxiosInstance, AxiosResponse, RawAxiosRequestHeaders } from 'axios';

import config from 'configs';
import { showAppNotification } from 'state/actions';
import { logoutAction } from 'state/actions/user.actions';
import { store } from 'state/store';
import { routes } from '../navigator';
export { apiRoutes } from './api.routes';

export class ApiService {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({
            baseURL: config.apiUrl,
        });
    }

    setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    removeToken() {
        this.axios.defaults.headers.common['Authorization'] = null;
    }

    private parseError(error: AxiosError) {
        if (error.response && error.response.status === 401) {
            store.dispatch(logoutAction());
        } else if (error.response && error.response.status === 409) {
            const errorData = error.response.data as any;
            let title = errorData.message;
            if (title) {
                let href;
                if (title.includes('Type matching')) {
                    href = `/#${routes.errorQueue}/?id=${errorData.id}`;
                    title = 'Import failed: no template was found.';
                }

                store.dispatch(
                    showAppNotification({
                        type: 'error',
                        title: title,
                        content: href
                            ? React.createElement('a', { href: href }, 'Check and fix')
                            : '',
                    })
                );
            }
        } else {
            store.dispatch(
                showAppNotification({
                    type: 'error',
                    title: 'Oops, something went wrong',
                    content: 'server could not process your request',
                })
            );
        }

        return null;
    }

    public async fetch<T>(
        path: string,
        method: 'get' | 'put' | 'post' | 'delete' = 'get',
        data?: any,
        headers?: RawAxiosRequestHeaders,
        throwError = false
    ): Promise<T | null> {
        try {
            const response = await this.axios[method](path, data, { headers });
            return response.data;
        } catch (error) {
            if (throwError) {
                throw error;
            }

            return this.parseError(error as AxiosError);
        }
    }

    public async fetchWithHeaders<T>(
        path: string,
        method: 'get' | 'put' | 'post' | 'delete' = 'get',
        data?: any,
        headers?: RawAxiosRequestHeaders
    ): Promise<AxiosResponse<T> | null> {
        try {
            const response = await this.axios[method](path, data, { headers });
            return response;
        } catch (error) {
            return this.parseError(error as AxiosError);
        }
    }

    public async getFile(path: string): Promise<Blob | null> {
        try {
            const response = await this.axios.get(path, {
                responseType: 'blob',
            });
            return response.data;
        } catch (error) {
            return this.parseError(error as AxiosError);
        }
    }

    public async getFileResponse(path: string): Promise<AxiosResponse<Blob> | null> {
        try {
            return await this.axios.get(path, {
                responseType: 'blob',
            });
        } catch (error) {
            return this.parseError(error as AxiosError);
        }
    }
}

const api = new ApiService();

export interface ApiParams {
    page?: number;
    size?: number;
    from?: string;
    to?: string;
    searchQuery?: string;
}

export { api };
