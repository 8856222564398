import { MenuProps } from 'antd';

import { PostProcessRuleLabel } from 'components/Template/PostProcessRules/PostProcessRuleLabel';
import { useHighlight } from 'components/Template/Selection/Highlight/helpers';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { Badge, Divider, ItemMenu, SelectionButton, SingleListItemTitle } from 'components/atomic';
import {
    clearTableColumnContentAction,
    clearTableColumnTitleAction,
    clearValueAction,
    tableColumnsAction,
} from 'state/actions';
import { TableColumnsItem } from 'state/types';

export const MarkTableColumnsItem = ({
    id,
    fieldId,
    title,
    value,
    titleId,
    contentIds,
    extractedContentIds,
    isActive,
    pageNum,
}: Props) => {
    const { dispatch, tableColumns, id: templateId, template } = useTemplate();
    const { selection } = tableColumns;

    const handleClearValue = () => {
        dispatch(clearValueAction(id));
    };

    const handleClearTitle = () => {
        dispatch(clearTableColumnTitleAction(id));
    };

    const handleClearContent = () => {
        dispatch(clearTableColumnContentAction(id));
    };

    const handleColumnButtonClick: ClickHandler = () => {
        dispatch(tableColumnsAction({ selection: 'column' }));
    };

    const handleTitleButtonClick: ClickHandler = event => {
        event.stopPropagation();
        dispatch(tableColumnsAction({ selection: 'title' }));
    };

    const handleContentButtonClick: ClickHandler = event => {
        event.stopPropagation();
        dispatch(tableColumnsAction({ selection: 'content' }));
    };

    const items: MenuProps['items'] = [
        {
            key: 'clearColumn',
            label: <div onClick={handleClearValue}>Clear column</div>,
        },
        {
            key: 'clearTitle',
            label: <div onClick={handleClearTitle}>Clear title</div>,
        },
        {
            key: 'clearContent',
            label: <div onClick={handleClearContent}>Clear value</div>,
        },
        {
            key: 'post-process-rule',
            label: (
                <PostProcessRuleLabel
                    templateId={templateId}
                    templateTypeId={template?.templateTypeId}
                    fieldId={fieldId}
                    label={title}
                    isTable
                />
            ),
        },
    ];

    const columnHighlight = useHighlight({
        value: [null],
        area: value,
        type: 'area',
    });

    const titleHighlight = useHighlight(
        {
            value: [titleId || null],
            type: 'box',
        },
        pageNum
    );

    const contentHighlight = useHighlight(
        {
            value: contentIds || [],
            type: 'box',
        },
        pageNum
    );

    const extractedHighlight = useHighlight(
        {
            value: extractedContentIds,
            type: 'box',
        },
        pageNum
    );

    return (
        <div className="single-item-multiline">
            <div className="single-item-multiline-row first">
                <SingleListItemTitle>
                    <span {...extractedHighlight}>{title}</span>
                </SingleListItemTitle>
                <div className="item-right">
                    {value !== null ? (
                        <Badge text="Column" {...columnHighlight} />
                    ) : (
                        <SelectionButton
                            text="Column"
                            onClick={handleColumnButtonClick}
                            active={isActive && selection === 'column'}
                        />
                    )}
                    <ItemMenu items={items} />
                </div>
            </div>
            {isActive && (
                <>
                    <Divider />
                    <div className="single-item-multiline-row second">
                        <span className="single-item-multiline-description">Mark manually</span>
                        {titleId !== null ? (
                            <Badge text="Title" {...titleHighlight} />
                        ) : (
                            <SelectionButton
                                text="Title"
                                onClick={handleTitleButtonClick}
                                active={isActive && selection === 'title'}
                            />
                        )}
                        {contentIds?.length ? (
                            <Badge text="Value" {...contentHighlight} />
                        ) : (
                            <SelectionButton
                                text="Value"
                                onClick={handleContentButtonClick}
                                active={isActive && selection === 'content'}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

interface Props extends TableColumnsItem {
    isActive: boolean;
}

type ClickHandler = React.DOMAttributes<HTMLDivElement>['onClick'];
