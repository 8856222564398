import { UserData } from 'state/types';
import { useAppSelector } from './redux.hooks';

export const useAuth = () => {
    return useAppSelector(state => state.user.isAuth);
};

export const useUserData = (): UserData => {
    const userData = useAppSelector(state => state.user.data);

    if (!userData) {
        return {
            email: '',
            name: '',
            role: 'GUEST',
            currentWorkspaceId: 1,
            workspaces: [],
        };
    }

    return userData;
};
