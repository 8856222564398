import { createReducer } from '@reduxjs/toolkit';

import {
    logoutAction,
    setAuthStateAction,
    setUserDataAction,
    setUserImageAction,
    setUserLoadingAction,
} from 'state/actions/user.actions';
import { UserState } from 'state/types';

const initialState: UserState = {
    isAuth: false,
    data: null,
    image: '',
    loading: false,
};

export const userReducer = createReducer(initialState, builder => {
    builder
        .addCase(setAuthStateAction, (state, { payload }) => {
            state.isAuth = payload;
        })
        .addCase(setUserDataAction, (state, { payload }) => {
            state.data = payload;
        })
        .addCase(logoutAction, state => {
            state.data = initialState.data;
            state.isAuth = initialState.isAuth;
        })
        .addCase(setUserImageAction, (state, { payload }) => {
            state.image = payload;
        })
        .addCase(setUserLoadingAction, (state, { payload }) => {
            state.loading = payload;
        });
});
