import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import { ListIconCelText } from 'components/List';
import { FileCheckIcon, DocumentIcon, StatusBadge, StatusBadgeType } from 'components/atomic';
import { TemplateByTypeData } from './DocumentTemplates/types';
import { StepNumber } from 'state/types';
import { DocumentTemplatesEdit } from './DocumentTemplates/DocumentTemplatesEdit';
import { Link } from 'react-router-dom';
import { routes } from 'navigator';

export const getColumns = (forceUpdate?: () => void) => {
    const columns: ColumnsType<TemplateByTypeData> = [
        {
            key: 'templateName',
            dataIndex: 'templateName',
            render: (name: string, item) => (
                <ListIconCelText
                    icon={<DocumentIcon />}
                    conditionIcon={<FileCheckIcon fill="#4c4c4c" />}
                    condition={item.isTemplateApproved}
                    text={name}
                    subtext={moment(item.createdAt).format('MMM DD, hh:mm')}
                />
            ),
        },
        {
            key: 'loaded',
            dataIndex: 'loaded',
            render: (value: number) => (
                <Link to={routes.documents}>
                    <span>{value} loaded</span>
                </Link>
            ),
        },
        {
            key: 'failed',
            dataIndex: 'failed',
            render: (value: number) => (
                <Link to={routes.errorQueue}>
                    <span>{value} failed</span>
                </Link>
            ),
        },
        {
            key: 'currentStep',
            dataIndex: 'currentStep',
            render: (value: StepNumber, item) => {
                const type: StatusBadgeType = item.isTemplateApproved
                    ? 'approved'
                    : value === 'COMPLETE'
                    ? 'active'
                    : 'not_active';
                const text = item.isTemplateApproved
                    ? 'Approved'
                    : value === 'COMPLETE'
                    ? 'Set'
                    : 'Not set';
                return <StatusBadge type={type}>{text}</StatusBadge>;
            },
        },
        {
            key: 'id',
            dataIndex: 'id',
            render: (value: number, item) => (
                <DocumentTemplatesEdit item={item} typeId={item.typeId} forceUpdate={forceUpdate} />
            ),
        },
    ];

    return columns;
};
