import { Button, Modal } from 'antd';
import { useState } from 'react';

import { useAppDispatch } from 'hooks/redux.hooks';
import { getListenerAction } from 'hooks/data.hooks';
import { apiRoutes, deleteTemplate } from 'services';

export const DeleteDocumentTemplateModal = ({ open, setOpen, id, typeId, templateName }: Props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const handleCancel = () => {
        setOpen(false);
        setLoading(false);
    };

    const handleDelete = async () => {
        setLoading(true);

        await deleteTemplate(id);
        dispatch(getListenerAction(`${apiRoutes.templatesByType}/${typeId}`));
        dispatch(getListenerAction(apiRoutes.templatesAll));

        setLoading(false);
        setOpen(false);
    };

    return (
        <Modal
            title={<span>Delete {templateName} Template</span>}
            open={open}
            centered
            width={580}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel} type="link">
                    Cancel
                </Button>,
                <Button
                    key="delete"
                    type="primary"
                    loading={loading}
                    onClick={handleDelete}
                    className="document-delete-button">
                    Delete template
                </Button>,
            ]}>
            <div>Are you sure you want to delete template?</div>
        </Modal>
    );
};

interface Props {
    id: number;
    typeId: number;
    open: boolean;
    templateName: string;
    setOpen: (value: boolean) => void;
}
