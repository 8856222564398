import { createListenerMiddleware } from '@reduxjs/toolkit';
import { redirect } from 'react-router-dom';

import { api, getUserDetails, getUserImage, refreshToken, uploadUserImage } from 'services';
import { StorageItem } from 'global/storage-items';
import {
    initializeFinishAction,
    loginAction,
    logoutAction,
    setAuthStateAction,
    setShowAddressTableAction,
    setUserDataAction,
    setUserImageAction,
    setUserLoadingAction,
    setWorkspaceAction,
    uploadUserImageAction,
    workspaceChangedAction,
} from 'state/actions';
import { RootState } from 'state/store.types';
import config from 'configs';
import { setCurrentWorkspace } from 'services/workspace.service';

export const userMiddleware = createListenerMiddleware<RootState>();

let refreshSessionInterval: NodeJS.Timer | null = null;

userMiddleware.startListening({
    actionCreator: setAuthStateAction,
    effect: async (action, { dispatch, getState }) => {
        if (action.payload) {
            const data = await getUserDetails();
            const image = await getUserImage();
            dispatch(setUserDataAction(data));
            dispatch(setUserImageAction(image));
        } else {
            localStorage.removeItem(StorageItem.token);
        }

        const { init } = getState();

        if (!init.initialized) {
            dispatch(initializeFinishAction());
        }
    },
});

userMiddleware.startListening({
    actionCreator: loginAction,
    effect: async (action, { dispatch }) => {
        const { accessToken } = action.payload;

        localStorage.setItem(StorageItem.token, accessToken);
        api.setToken(accessToken);
        dispatch(setAuthStateAction(true));

        refreshSessionInterval = setInterval(async () => {
            const refresh = await refreshToken();

            if (refresh) {
                api.setToken(refresh?.accessToken);
            }
        }, config.refreshSessionInterval);

        redirect('/');
    },
});

userMiddleware.startListening({
    actionCreator: logoutAction,
    effect: async (action, { dispatch }) => {
        dispatch(setAuthStateAction(false));

        if (refreshSessionInterval) {
            clearInterval(refreshSessionInterval);
        }
    },
});

userMiddleware.startListening({
    actionCreator: uploadUserImageAction,
    effect: async ({ payload }, { dispatch }) => {
        await uploadUserImage(payload);

        const image = await getUserImage();

        dispatch(setUserImageAction(image));
    },
});

userMiddleware.startListening({
    actionCreator: setWorkspaceAction,
    effect: async ({ payload }, { dispatch }) => {
        dispatch(setUserLoadingAction(true));

        await setCurrentWorkspace(payload);

        const data = await getUserDetails();

        dispatch(setUserDataAction(data));
        dispatch(setUserLoadingAction(false));
        dispatch(workspaceChangedAction());
    },
});

userMiddleware.startListening({
    actionCreator: setUserDataAction,
    effect: async ({ payload }, { dispatch }) => {
        if (!payload) {
            return;
        }

        let showAddressTable = false;

        if (['ROLE_ADMIN', 'ROLE_EDITOR'].includes(payload.role)) {
            const workspace = payload.workspaces.find(
                item => item.id === payload.currentWorkspaceId
            );
            showAddressTable = !!workspace?.showAddressTable;
        }

        dispatch(setShowAddressTableAction(showAddressTable));
    },
});
