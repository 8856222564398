import { DocumentApproveStatus } from 'components/Documents/types';
import { AMOUNT_FILTER_DEFAULT_VALUE } from './AmountFilter/AmountFilter';
import { APPROVED_FILTER_DEFAULT_VALUE } from './ApprovedFilter/ApprovedFilter';
import { DATE_FILTER_DEFAULT_VALUE } from './DateFilter';

const AMOUNT_FILTER_STORAGE_PREFIX = 'filter.';
const DATE_FILTER_STORAGE_KEY = 'dates_filter';
const APPROVED_FILTER_STORAGE_KEY = 'approved_filter';

export const getSavedAmountFilterValue = (pageId: string) => {
    const amount = localStorage.getItem(`${AMOUNT_FILTER_STORAGE_PREFIX}${pageId}`) || '';
    return parseInt(amount, 10) || AMOUNT_FILTER_DEFAULT_VALUE;
};

export const saveAmountFilterValue = (pageId: string, value?: number) => {
    if (value) {
        localStorage.setItem(`${AMOUNT_FILTER_STORAGE_PREFIX}${pageId}`, value.toString());
    }
};

export const getSavedDateFilterValue = () => {
    const value = sessionStorage.getItem(DATE_FILTER_STORAGE_KEY);
    return value || DATE_FILTER_DEFAULT_VALUE;
};

export const saveDateFilterValue = (value?: string) => {
    if (value) {
        sessionStorage.setItem(DATE_FILTER_STORAGE_KEY, value);
    }
};

export const getApprovedFilterValue = () => {
    const value = sessionStorage.getItem(APPROVED_FILTER_STORAGE_KEY);

    if (typeof value === 'string') {
        try {
            JSON.parse(value);
        } catch (error) {}
    }

    return APPROVED_FILTER_DEFAULT_VALUE;
};

export const saveApprovedFilterValue = (value: DocumentApproveStatus) => {
    if (value) {
        sessionStorage.setItem(APPROVED_FILTER_STORAGE_KEY, value.toString());
    }
};
