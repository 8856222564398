import { apiRoutes } from 'services';
import { PostProcessRule } from './types';
import { TemplateData } from '../types';
import { TemplateTypeResponseData } from 'components/Templates';

export const getApiPath = (templateId: number) =>
    `${apiRoutes.templates}/${templateId}/${apiRoutes.postProcessRules}`;

export const getTemplateTypePath = (templateTypeId?: number) =>
    `${apiRoutes.templateTypes}/${templateTypeId}`;

export const getItemTitle = (fieldName: string, item: PostProcessRule) => {
    if (item.type === 'split') {
        return `${item.splitPosition || ''}${item.oldValue || ''}`;
    }

    if (typeof item.oldValue === 'string' && typeof item.newValue === 'string') {
        return `${item.oldValue} → ${item.newValue || '""'}`;
    }

    return `${fieldName} → ${item.type.replace(/^\w/, s => s.toUpperCase())}`;
};

export const getFieldsOptions = (
    template: TemplateData | null,
    isTable: boolean
): SelectOptions => {
    return (
        template?.fields
            .filter(item => item.isTable === isTable)
            .map(item => ({
                label: item.label,
                value: item.fieldId,
            })) || []
    );
};

export const getFieldById = (template: TemplateData | null, id: number) => {
    return template?.fields.find(item => item.fieldId === id) || null;
};

export const getDefaultDateAfterValue = (
    type: string,
    templateType: TemplateTypeResponseData | null
) => (type === 'date' ? templateType?.outDatePattern || '' : '');

export const getSplitValues = (value: string): SplitValues => {
    const match = value.match(/^first|^last/);

    if (!match) {
        return {
            value,
        };
    }

    return {
        value: value.replace(match[0], ''),
        splitValue: match[0],
    };
};

interface SplitValues {
    value: string;
    splitValue?: string;
}
