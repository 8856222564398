import { useDrawer } from 'components/DrawerContainer';
import { PostProcessRuleForm } from './PostProcessRuleForm';

export const PostProcessRuleLabel = ({
    fieldId,
    templateId,
    templateTypeId,
    label,
    isTable,
}: Props) => {
    const { openDrawer } = useDrawer();

    const handlePostProcessRuleClick = () => {
        openDrawer(
            'Set post-process rule',
            <PostProcessRuleForm
                fieldId={fieldId}
                fieldName={label}
                templateId={templateId}
                templateTypeId={templateTypeId}
                isTable={!!isTable}
            />
        );
    };

    return <div onClick={handlePostProcessRuleClick}>Set post process rule</div>;
};

interface Props {
    fieldId: number;
    templateId: number;
    templateTypeId?: number;
    label: string;
    isTable?: boolean;
}
